<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<div class="page-head">
				<el-radio-group v-model="activeName" size="medium" @change="handleChangeTab">
					<el-radio-button v-for="item in $t('seller.options.customerCoupon')" :key="item.value" :label="item.value">{{ item.label }}</el-radio-button>
				</el-radio-group>
				<div>
					<router-link :to="{ name: 'couponCreate',query: { channel: 1 }}"><el-button type="primary" round size="medium">+{{ $t('seller.actions.createCoupon') }}</el-button></router-link>
				</div>
			</div>
			<el-table v-loading="listLoading" :data="pageList" class="seller-table">
				<el-table-column :label="$t('seller.tableHead.coupon')" min-width="240px">
					<template slot-scope="scope">
						<div class="coupon-item">
							<div class="item-quato"><span class="price">￥<span>{{ scope.row.money }}</span></span>{{ $t('seller.market.shopCoupon') }}</div>
							<div class="item-text">{{ $t('seller.customer.crowdText13') }}<span>{{ scope.row.quota }}</span>{{ $t('seller.customer.crowdText14') }}</div>
							<div class="item-text">{{ scope.row.use_begin_time | parseTime('{y}-{m}-{d} {h}:{i}')}} - {{ scope.row.use_end_time | parseTime('{y}-{m}-{d} {h}:{i}')}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.formItem.name')" prop="name" min-width="120px"></el-table-column>
				<el-table-column :label="$t('seller.tableHead.crowd')" min-width="160px">
					<template slot-scope="scope">
						<span v-if="scope.row.pop_type === 3" :class="{red:!scope.row.crowd}">
							{{ scope.row.crowd ? scope.row.crowd.title:$t('seller.customer.crowdText15') }}
						</span>
						<span v-else :class="{ red: scope.row.pop_type === 0}">
							{{ scope.row.pop_type | typeFilter($t('seller.filters.crowdCouponType'))}}
						</span>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.status')" v-if="activeName > 0">
					<template slot-scope="scope">
						<el-tag :type="scope.row.status | typeFilter({1:'success',2:'danger'})" size="mini">{{ scope.row.status_desc}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.limitedCollar')" prop="limit_give_num"></el-table-column>
				<el-table-column :label="$t('seller.tableHead.circulation')" prop="give_num"></el-table-column>
				<el-table-column :label="$t('seller.tableHead.received')" prop="receive_num"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button v-if="scope.row.pop_type === 0" type="text" @click="handleShowSet(scope)">{{ $t('seller.actions.setCouponCrowd') }}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize" @pagination="getList"></pagination>
			<el-dialog
				:title="$t('seller.actions.setCouponCrowd')"
				:visible.sync="dialogVisible"
				width="312px"
				custom-class="seller-dialog">
				<el-radio-group v-model="postForm.pop_type">
					<el-radio v-for="item in $t('seller.options.customerType')" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
				</el-radio-group>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="handleSubmit" size="medium" round>{{$t('seller.actions.confirm')}}</el-button>
					<el-button @click="dialogVisible = false" size="medium" round>{{$t('seller.actions.cancel')}}</el-button>
				</span>
			</el-dialog>
		</el-card>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchMaterialCounpon, updateMaterialCounpon  } from "@/api/seller/customer"
const defaultForm = {
	coupon_id: 0,
	pop_type: 2
}
export default {
	components: { Pagination },
	data() {
		return {
			activeName: 0,
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			dialogVisible: false,
			postForm: Object.assign({}, defaultForm)
		}
	},
	created () {
		this.getList()	
	},
	methods: {
		async getList() {
			this.listLoading = true
			const params = {
				pop_type: this.activeName,
				page_index: (this.pageCurr - 1) *this.pageSize,
				page_size: this.pageSize
			}
			const { data } = await fetchMaterialCounpon(params)
			this.pageList = data.list
			this.pageTotal = parseInt(data.total)
			this.listLoading = false
		},
		handleChangeTab() {
			this.pageCurr = 1
			this.getList()
		},
		handleShowSet({ row }) {
			this.postForm = Object.assign({},defaultForm)
			this.postForm.coupon_id = row.coupon_id
			this.dialogVisible = true
		},
		handleSubmit() {
			updateMaterialCounpon(this.postForm).then(() => {
				this.dialogVisible = false
				this.getList()
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-head {
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
	}
	.coupon-item {
		color: #ff5000;
		line-height: 1;
		padding: 8px 0 8px 14px;
		width: 266px;
		position: relative;
		background: #ffede6;
		border-radius: 4px;
		.item-quato {
			font-size: 12px;
			.price {
				font-size: 10px;
				margin-right: 5px;
				span {
					font-size: 20px;
				}
			}
		}
		.item-text {
			margin-top: 6px;
			font-size: 12px;
		}
	}
	.red {
		color: #ff5000;
	}
}
</style>